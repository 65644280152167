.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.App-header {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Input-Form {
  color: white;
}

.Input {
  max-width: 600px;
  margin: 0 auto;
}

.Text-Input {
  margin-left: 5px;
  width: 500px;
}

label {
  margin-bottom: 5px;
  display: block;
  width: 600px;
  text-align: right;
}